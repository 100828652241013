import axios from 'axios'
import axiosRetry from 'axios-retry'
import { getUserId } from '../contexts/UserDataContext'
import { getTenant, getChannel, getFlowId, isSod } from '../Helpers'
import {
  appendSearchEngineToProductResponse,
  appendSlugToProduct,
  appendSlugToProducts,
  appendSlugToProductsCategory,
} from './appendSlug'
import constants from './constants'
import getConfig from '../Helpers/getConfig'

const retryConfig = {
  retries: 2,
  shouldResetTimeout: true,
  retryCondition: (error) => error?.config?.url !== 'orders',
}
export default class ClientAPI {
  constructor(config = {}, url) {
    this.config = typeof window !== 'undefined' ? window.__ENV__ : config
    this.tenant = getTenant(url)
    this.headers = {
      'X-FlowId': getFlowId(),
      tenant: this.tenant,
      'X-SessionId': getUserId(),
      'x-channel': getChannel(this.tenant, this.config ? this.config.isWalstore : false),
    }
  }

  isWalstore() {
    return this.config.isWalstore === 'true'
  }

  async bffInstance(retry = true) {
    const baseURLbff = getConfig('baseURLbff')
    const bffInstance = axios.create({
      baseURL: baseURLbff,
      timeout: this.config.timeout,
      headers: this.headers,
      withCredentials: true,
    })
    if (retry) {
      axiosRetry(bffInstance, retryConfig)
    }
    return Promise.resolve(bffInstance)
  }

  storageInstance() {
    const storageInstance = axios.create({
      baseURL: this.config.storageBaseUrl,
      timeout: this.config.timeout,
    })
    axiosRetry(storageInstance, retryConfig)
    return storageInstance
  }

  getProductBySKU(sku, appId, storeId) {
    return new Promise(async (resolve, reject) => {
      const client = await this.bffInstance()

      let url = this.config.newBff
        ? `products/${sku}?appId=${appId}&ts=${Date.now()}`
        : `products/?sku=${sku}&appId=${appId}&ts=${Date.now()}`

      if (storeId) {
        url = `${url}&storeId=${storeId}`
      }

      client
        .request({
          withCredentials: true,
          url,
          method: 'get',
          timeout: 10000,
        })
        .then((response) => {
          resolve(appendSlugToProduct(this.config.newBff ? response.data : response.data[0]))
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getDiscounts() {
    return this.doGet({ url: 'discounts' })
  }

  getTopPromotionProducts(campaign, carouselId) {
    return this.doGet({
      url: `products/ssr/campaign/${campaign}/BuySmart/${carouselId}`,
      timeout: constants.timeout.TEN_SECONDS,
    })
  }

  getTopPromotionProductsByCarouselId(campaign, carouselId) {
    return this.doGet({
      url: `products/campaign/${campaign}/carousel/${carouselId}`,
      timeout: constants.timeout.TEN_SECONDS,
    })
  }

  getSupermarketItems() {
    const storage = this.storageInstance()
    const request = storage.request({
      url: this.config.supermarketItemsJsonPath,
      method: 'get',
    })
    return this.handleRequest(request)
  }

  getTopPromotionProductsSSR(campaign) {
    return this.doGet({ url: `products/campaign/${campaign}/carousel`, timeout: constants.timeout.TEN_SECONDS })
  }

  category(body) {
    return new Promise(async (resolve, reject) => {
      const client = await this.bffInstance()
      client
        .request({
          withCredentials: true,
          url: 'category',
          method: 'post',
          timeout: constants.timeout.TEN_SECONDS,
          data: body,
        })
        .then((response) => {
          resolve(appendSearchEngineToProductResponse(appendSlugToProductsCategory(response.data), response))
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  search(body) {
    return new Promise(async (resolve, reject) => {
      const client = await this.bffInstance()
      client
        .request({
          withCredentials: true,
          url: 'search',
          method: 'post',
          timeout: 10000,
          data: body,
        })
        .then((response) => {
          resolve(appendSearchEngineToProductResponse(appendSlugToProductsCategory(response.data), response))
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getProductBySKUFromCatalog(sku) {
    return this.doGet({ url: `catalog/${sku}`, timeout: constants.timeout.TEN_SECONDS })
  }

  getBanners() {
    return this.doGet({ url: 'banners', timeout: constants.timeout.TWENTY_SECONDS })
  }

  async getAds(arr) {
    const data = {
      bannerIndexes: arr,
    }
    return await this.doPost({ url: 'advertising/banners', data, timeout: constants.timeout.TEN_SECONDS })
  }

  getAvailableCountDown() {
    return this.doGet({ url: 'banners/countdown' })
  }

  getProductSelections(skuArray, storeId, carouselId, spaSearchTerm) {
    return new Promise(async (resolve, reject) => {
      const client = await this.bffInstance()

      let url = `products/selections?sku=${skuArray.join('&sku=')}`

      if (storeId) {
        url = `${url}&storeId=${storeId}`
      }
      if (carouselId) {
        url = `${url}&carouselId=${carouselId}`
      }
      if (spaSearchTerm && isSod()) {
        url = `${url}&spaSearchTerm=${spaSearchTerm}`
      }
      client
        .request({
          withCredentials: true,
          url,
          method: 'get',
          timeout: constants.timeout.FIVE_SECONDS,
        })
        .then((response) => {
          resolve(appendSlugToProducts(response.data))
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  getCategories() {
    return this.doGet({ url: 'categories' })
  }

  getCombinables(data) {
    return this.doPost({ url: 'promotion/combinable', data })
  }

  getGridSettings() {
    return this.doGet({ url: 'banners/grids', timeout: constants.timeout.TEN_SECONDS })
  }

  sendAdvisorEvent(data) {
    return this.doPost({ url: 'advisor/event', data: { ...data, wid: 'f59c745c3a2a11eb93418e38af452a64' } })
  }

  sendAdvisorSpaEvent(data) {
    return this.doPost({ url: 'advisor/spa-event', data: { ...data, wid: 'f59c745c3a2a11eb93418e38af452a64' } })
  }

  getWalstoreStores() {
    return this.doGet({ url: 'walstore/stores' })
  }

  getSearchSuggestions(data) {
    return this.doPost({ url: 'suggestions', data })
  }

  getComplementaryProducts(data) {
    return this.getRecommendedProducts(data, 'recommender/complementaries')
  }

  getMostPurchasedProducts(data) {
    if (data && !data.walmartId) {
      return { data: [] }
    }

    return this.getRecommendedProducts(data, 'recommender/most-purchased')
  }

  getSubstituteProducts(data) {
    return this.getRecommendedProducts(data, 'recommender/substitutes')
  }

  handleRequest(request) {
    return new Promise((resolve, reject) => {
      request.then((response) => resolve(response.data)).catch((error) => reject(error))
    })
  }

  async doPost({ url, data, timeout = constants.timeout.FIVE_SECONDS, retry = true }) {
    const client = await this.bffInstance(retry)

    const request = client.request({
      withCredentials: true,
      url,
      method: 'POST',
      data: JSON.stringify(data),
      timeout,
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
    })

    return this.handleRequest(request)
  }

  async doLogPost({ data, timeout, retry = true }) {
    try {
      return await this.doPost({ url: 'logs', data, timeout, retry })
    } catch (error) {
      // do nothing, due to there is no more log registers
    }
    return undefined
  }

  async doGet({ url, timeout = constants.timeout.FIVE_SECONDS, retry = true }) {
    const client = await this.bffInstance(retry)
    const request = client.request({
      withCredentials: true,
      url,
      method: 'GET',
      timeout,
    })

    return this.handleRequest(request)
  }

  async getPromotionsConfig() {
    const client = await this.bffInstance()
    const url = '/storage/promotions'

    const request = client.request({
      withCredentials: true,
      url,
      method: 'get',
    })

    return this.handleRequest(request)
  }

  checkSKUs(data) {
    return this.doPost({ url: 'cart/validation', data, timeout: constants.timeout.TEN_SECONDS })
  }

  async getCheckoutURL(url, data) {
    const client = await this.bffInstance()
    const isGTP = document.cookie.includes('cloud=GTP')

    const logData = {
      data,
      msg: 'createOrder request',
      isWalstore: this.isWalstore(),
      isGTP,
    }

    try {
      this.doLogPost({ data: [logData], timeout: constants.timeout.TEN_SECONDS })
      const response = await client.request({
        withCredentials: true,
        url,
        method: 'post',
        data,
      })

      logData.msg = 'Create order request success'

      this.doLogPost({ data: [logData], timeout: constants.timeout.TEN_SECONDS })

      return response.data
    } catch (error) {
      logData.msg = 'Create order request failed'
      logData.statusCode = error.response?.status
      logData.errorMsg = {
        name: error.name,
        message: error.message,
        data: error.response?.data,
        isWalstore: this.isWalstore(),
        isGTP,
      }

      this.doLogPost({ data: [logData], timeout: constants.timeout.TEN_SECONDS })

      const isBadRequestStatus = error.response?.status === 400

      if (isBadRequestStatus) {
        return error.response.data
      }
      throw error
    }
  }

  confirmAccount(jwt) {
    return this.doGet({ url: `signup/confirmAccount?params=${jwt}`, timeout: constants.timeout.TWENTY_SECONDS })
  }

  credentialsValidate(jwt) {
    return this.doGet({ url: `credentials/validate?params=${jwt}`, timeout: constants.timeout.TWENTY_SECONDS })
  }

  validateExistingUser(data) {
    return this.doPost({
      url: 'signup/validateExistingUser',
      data,
      timeout: constants.timeout.TWENTY_SECONDS,
      retry: false,
    })
  }

  credentialsReset(data) {
    return this.doPost({ url: 'credentials/reset', data, timeout: constants.timeout.TWENTY_SECONDS })
  }

  async getRecommendedProducts(data, url) {
    const client = await this.bffInstance()
    const response = await client.request({
      withCredentials: true,
      url,
      method: 'post',
      timeout: constants.timeout.TEN_SECONDS,
      data,
    })

    const products = response.data.products ? response.data?.products : response.data
    const productsData = products.length > 0 ? appendSlugToProducts(products) : []

    return { ...response, data: productsData, total: response.data.total }
  }

  passwordRecovery(email) {
    return this.doPost({
      url: `password-recovery`,
      data: { email },
      timeout: constants.timeout.TWENTY_SECONDS,
      retry: false,
    })
  }

  userRegistry(data) {
    return this.doPost({ url: 'signup/register', data, timeout: constants.timeout.TWENTY_SECONDS, retry: false })
  }

  resendAccountConfirmationEmail(data) {
    return this.doPost({ url: 'signup/confirmAccount/resend', data, timeout: constants.timeout.TWENTY_SECONDS })
  }

  confirmSignupRetry(email) {
    return this.doPost({ url: `signup/retryConfirmation`, data: { email }, timeout: constants.timeout.TWENTY_SECONDS })
  }

  promotionEvaluate(data) {
    return this.doPost({
      url: 'promotion/evaluate',
      data,
      timeout: constants.timeout.FIVE_SECONDS,
    })
  }

  getPromotionalPopUp() {
    return this.doGet({ url: 'promotion/promotionalPopUp' })
  }

  getLegalInformationFile(fileName) {
    return this.doGet({ url: `legalInformation/${fileName}` })
  }

  getGenders() {
    return this.doGet({ url: 'customers/genders' })
  }

  postUpdateCustomer(data) {
    return this.doPost({
      url: 'customers/update',
      data,
      timeout: constants.timeout.TEN_SECONDS,
    })
  }

  getRetract() {
    return this.doPost({
      url: `retract`,
      timeout: constants.timeout.TWENTY_SECONDS,
      retry: false,
    })
  }
}
