import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { getProductLimit } from '../../../../Helpers/index'

export const isAddButtonDisabled = (quantity, limit) => quantity >= limit

export const renderQuantity = (q, hit) => {
  const soldByWeight = hit?.attributes?.soldByWeight

  if (soldByWeight === 'true') {
    const defaultQuantity = hit?.attributes?.defaultQuantity
    const kg = q * defaultQuantity
    const parts = kg.toString().split('.')
    const decimals = parts[1] ? parts[1].length : 0

    if (decimals === 2) {
      return q < 100 ? kg + 'kg' : '+50kg'
    }

    return q < 100 ? kg.toFixed(1) + 'kg' : '+50kg'
  }

  return q < 100 ? q : '+99'
}

const CartQuantityStepper = ({ hit, productQuantity, origin, validateUnit, onAddToCart, onRemoveOneFromCart }) => {
  const { limit } = useSelector((s) => s.campaignConfigReducer.goToPayMessageConfig.digitLimitToCarryConfig)
  const productLimit = getProductLimit(hit.max, limit)

  const handleAdd = (event) => {
    event.preventDefault()
    onAddToCart(hit, origin)
  }
  const handleRemove = (event) => {
    event.preventDefault()
    onRemoveOneFromCart(hit, origin)
  }
  return (
    <div className="addProductToCart walmart-add-cart-button row cp-no-select">
      <button
        className="text-center pt-5 width-32"
        onClick={handleRemove}
        type="button"
        data-testid="minus-sign-button"
      >
        <i className="zmdi zmdi-minus" />
      </button>
      <div className="text-center pt-5 product-exists-cart" style={validateUnit ? { color: 'red' } : {}}>
        {renderQuantity(productQuantity, hit)}
      </div>
      <button
        disabled={isAddButtonDisabled(productQuantity, productLimit)}
        className="text-center pt-5 width-32"
        onClick={handleAdd}
        type="button"
        data-testid="plus-sign-button"
      >
        <i className="zmdi zmdi-plus" />
      </button>
    </div>
  )
}

CartQuantityStepper.propTypes = {
  productQuantity: PropTypes.number.isRequired,
  hit: PropTypes.object.isRequired,
  validateUnit: PropTypes.any.isRequired,
  origin: PropTypes.string.isRequired,
  onRemoveOneFromCart: PropTypes.func.isRequired,
  onAddToCart: PropTypes.func.isRequired,
}

export default CartQuantityStepper
